import { Transition } from '@tailwindui/react';
import { type ReactNode, useRef, useState } from 'react';
import { IntercomProvider, useIntercom } from 'react-use-intercom';

import { useAwaitFullScreenConfirmCancelModal } from '../../../src/components/ConfirmCancelModalContext';
import { CalendarClockIcon } from '../../../src/components/icons/CalendarIcon';
import { ChatBubbleIcon } from '../../../src/components/icons/Chat/ChatBubbleIcon';
import { CloseIcon } from '../../../src/components/icons/CloseIcon';
import { ThickLineEmailIcon } from '../../../src/components/icons/EmailIcon';
import {
  PlayCircleIcon,
  RoundedPlayIcon,
} from '../../../src/components/icons/PlayIcon';
import { HelpQuestionIcon } from '../../../src/components/icons/QuestionIcon';
import config from '../../../src/config';
import { useOutsideClick } from '../../../src/hooks/useOutsideClick';
import { getStaticAssetPath } from '../../../src/utils/assets';
import { BrowserTimeoutCtrl } from '../../../src/utils/BrowserTimeoutCtrl';
import { loadImageAsPromise } from '../../../src/utils/media';
import { useSSRLayoutEffect } from '../hooks/useSSRLayoutEffect';

const DEMO_VIDEO_URL = getStaticAssetPath('videos/luna-park-overview.mp4');
const DEMO_VIDEO_POSTER_URL = getStaticAssetPath(
  'images/luna-park-overview-poster-md.png'
);

function Divider() {
  return (
    <div
      className='w-full h-px rounded-sm'
      style={{
        background: 'rgba(0, 0, 0, 0.10)',
      }}
    ></div>
  );
}

function HelpButton(props: {
  icon: ReactNode;
  label: string;
  onClick?: () => void;
  disabled?: boolean;
}) {
  return (
    <button
      type='button'
      className='btn w-full flex items-center gap-2.5 font-bold px-4 py-2 text-main-layer text-sms hover:text-red-006'
      onClick={props.onClick}
    >
      {props.icon}
      <div>{props.label}</div>
    </button>
  );
}

function Group(props: { children?: ReactNode; className?: string }) {
  return (
    <div className={`w-full flex flex-col ${props.className ?? ''}`}>
      {props.children}
    </div>
  );
}

function FullScreenVideoPlayer(props: {
  videoUrl: string;
  posterUrl: string;
  onClose: () => void;
}) {
  const [show, setShow] = useState(true);
  const ref = useRef(null);

  const onClose = () => {
    setShow(false);
    const ctrl = new BrowserTimeoutCtrl();
    ctrl.set(() => props.onClose(), 700);
  };

  useOutsideClick(ref, () => onClose());

  return (
    <Transition
      show={show}
      enter='transition-opacity duration-700'
      enterFrom='opacity-0'
      enterTo='opacity-100'
      leave='transition-opacity duration-700'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <div
        className={`absolute top-0 left-0 w-full h-full z-50 flex 
      items-center justify-center overflow-auto scrollbar bg-lp-black-004 backdrop-blur`}
      >
        <div
          ref={ref}
          className='w-full max-w-200 rounded-1.5lg border border-secondary overflow-hidden'
        >
          <video
            src={props.videoUrl}
            poster={props.posterUrl}
            controls
            autoPlay
          />
        </div>
      </div>
    </Transition>
  );
}

function VideoPreview(props: {
  videoUrl: string;
  posterUrl: string;
  label: string;
  durationSecs: number;
  onClick?: () => void;
}) {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const onClick = () => {
    triggerModal({
      kind: 'custom',
      noWrapper: true,
      element: (p) => (
        <Transition
          appear={true}
          show={true}
          enter='transition-opacity duration-700'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity duration-150'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <FullScreenVideoPlayer
            videoUrl={props.videoUrl}
            posterUrl={props.posterUrl}
            onClose={p.internalOnCancel}
          />
        </Transition>
      ),
    });
    props.onClick?.();
  };

  const duration = `${Math.ceil(props.durationSecs / 60)}m`;

  return (
    <div>
      <div
        className='relative hidden md:flex items-center justify-center rounded-lg'
        style={{
          backgroundImage: `url(${props.posterUrl})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          aspectRatio: '16 / 9',
        }}
      >
        <button
          type='button'
          onClick={onClick}
          className='hover:scale-120 transform transition-transform'
        >
          <PlayCircleIcon className='w-10 h-10 transform hover:scale-110 transition-transform' />
        </button>
        <div
          className='absolute flex items-center justify-between text-white text-2xs font-bold px-3.5 w-full bottom-1'
          style={{
            textShadow: '2px 2px 4px rgb(0 0 0 / 80%)',
          }}
        >
          <div>{props.label}</div>
          <div>{duration}</div>
        </div>
      </div>
      <div className='flex md:hidden'>
        <HelpButton
          icon={<RoundedPlayIcon />}
          label={`Watch ${props.label} (${duration})`}
          onClick={onClick}
        />
      </div>
    </div>
  );
}

function HelpPanel(props: { onClose: () => void }) {
  const { onClose } = props;

  const intercom = useIntercom();

  return (
    <div
      className='w-full flex flex-col items-start flex-shrink-0 rounded-xl p-2.5 bg-white gap-1 transform translate-y-12.5 md:translate-y-0'
      style={{
        filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15))',
      }}
    >
      <header className='w-full flex md:hidden items-center justify-between text-main-layer px-2 py-1'>
        <div className='font-bold'>Help & Contact</div>
        <button type='button' onClick={() => onClose()}>
          <CloseIcon className='w-3.5 h-3.5 fill-current' />
        </button>
      </header>
      <Group>
        <VideoPreview
          videoUrl={DEMO_VIDEO_URL}
          posterUrl={DEMO_VIDEO_POSTER_URL}
          label='Luna Park Overview'
          durationSecs={82}
          onClick={() => onClose()}
        />
      </Group>
      <Group>
        <HelpButton
          icon={<ChatBubbleIcon />}
          label='Chat Support'
          onClick={() => {
            intercom.show();
            onClose();
          }}
        />
        <HelpButton
          icon={<CalendarClockIcon />}
          label='Schedule a Demo'
          onClick={() => {
            window.open('https://lunapark.com/contact/', '_blank');
            onClose();
          }}
        />
      </Group>
      <Divider />
      <Group>
        <HelpButton
          icon={<ThickLineEmailIcon />}
          label='support@lunapark.com'
          onClick={() => {
            window.open('mailto:support@lunapark.com');
            onClose();
          }}
        />
      </Group>
    </div>
  );
}

function HelpLauncher(props: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) {
  const { open, setOpen } = props;

  const intercom = useIntercom();

  const togglePanel = () => {
    if (intercom?.isOpen) {
      intercom.hide();
    }
    setOpen(!open);
  };
  return (
    <button
      type='button'
      className='w-10 h-10 lg:w-12.5 lg:h-12.5 rounded-full bg-lp-blue-004 flex items-center justify-center transform -translate-x-4 -translate-y-4 md:translate-x-0 md:translate-y-0 hover:scale-120 transition-transform'
      style={{
        filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25))',
      }}
      onClick={togglePanel}
    >
      <div className='transform scale-80 lg:scale-100'>
        <HelpQuestionIcon />
      </div>
    </button>
  );
}

export function HelpWidget(props: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) {
  const { open, setOpen } = props;

  useSSRLayoutEffect(() => {
    loadImageAsPromise(DEMO_VIDEO_POSTER_URL);
  }, []);

  const ref = useRef(null);

  useOutsideClick(ref, () => {
    setOpen(false);
  });

  return (
    <IntercomProvider
      appId={config.misc.intercomAppId}
      autoBoot
      autoBootProps={{
        hideDefaultLauncher: true,
        verticalPadding: 80,
      }}
      shouldInitialize={open}
    >
      <div
        ref={ref}
        className='w-full md:w-auto fixed bottom-0 md:bottom-4 right-0 md:right-4 z-[9999] flex flex-col items-end gap-2.5 font-Montserrat'
      >
        <Transition
          className='w-full'
          show={open}
          enter='transition-opacity duration-150'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity duration-300'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          {(ref) => (
            <div className='w-full md:w-65 z-5' ref={ref}>
              <HelpPanel onClose={() => setOpen(false)} />
            </div>
          )}
        </Transition>
        <HelpLauncher open={open} setOpen={setOpen} />
      </div>
      <Transition
        className='w-full h-full fixed z-15'
        show={open}
        enter='transition-opacity duration-150'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-opacity duration-300'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        <div className='w-full h-full flex md:hidden bg-black bg-lp-black-004 backdrop-blur'></div>
      </Transition>
    </IntercomProvider>
  );
}
