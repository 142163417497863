import { getStaticAssetPath } from '../../src/utils/assets';

export const OG_GENERIC_IMAGE = {
  url: getStaticAssetPath('/images/market/generic.png'),
  params: {
    type: 'image/png',
    width: 1200,
    height: 630,
  },
};

export class MetadataUtils {
  static GENERIC_IMAGE = {
    url: getStaticAssetPath('images/market/generic.png'),
    params: {
      type: 'image/png',
      width: 1200,
      height: 630,
    },
  };
  static MakeImage(
    url: string,
    params?: { type?: string; width: number; height: number }
  ) {
    const meta = [
      {
        name: 'og:image',
        content: url,
      },
      {
        property: 'og:image',
        content: url,
      },
    ];
    if (params) {
      if (params.type) {
        meta.push({
          name: 'og:image:type',
          content: params.type,
        });
        meta.push({
          property: 'og:image:type',
          content: params.type,
        });
      }
      meta.push({
        name: 'og:image:width',
        content: params.width.toString(),
      });
      meta.push({
        property: 'og:image:width',
        content: params.width.toString(),
      });
      meta.push({
        name: 'og:image:height',
        content: params.height.toString(),
      });
      meta.push({
        property: 'og:image:height',
        content: params.height.toString(),
      });
    }
    return meta;
  }

  static MakeTitle(title: string) {
    return [
      { title: `${title} | Luna Park` },
      {
        property: 'og:title',
        content: `${title} | Luna Park`,
      },
    ];
  }

  static MakeDesc(desc: string) {
    return [
      {
        name: 'description',
        content: desc,
      },
      {
        name: 'og:description',
        content: desc,
      },
    ];
  }

  static MakeUrl(url: string) {
    return [
      {
        name: 'og:url',
        content: url,
      },
      {
        tagName: 'link',
        rel: 'canonical',
        href: url,
      },
    ];
  }

  static Make(params: {
    title?: string;
    desc?: string;
    url?: string;
    image?:
      | {
          url: string;
          params?: {
            type?: string;
            width: number;
            height: number;
          };
        }
      | 'generic';
  }) {
    const meta = [];
    if (params.title) {
      meta.push(...this.MakeTitle(params.title));
    }
    if (params.desc) {
      meta.push(...this.MakeDesc(params.desc));
    }
    if (params.url) {
      meta.push(...this.MakeUrl(params.url));
    }
    if (params.image) {
      if (params.image === 'generic') {
        meta.push(
          ...this.MakeImage(this.GENERIC_IMAGE.url, this.GENERIC_IMAGE.params)
        );
      } else {
        meta.push(...this.MakeImage(params.image.url, params.image.params));
      }
    }
    return meta;
  }
}
