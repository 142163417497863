import { useMemo } from 'react';

import { useAnalytics } from '../AnalyticsContext';
import { AppAnalytics } from './shared';

export function useAnonAppAnalytics(): AppAnalytics {
  const analytics = useAnalytics();
  return useMemo(
    () => new AppAnalytics(analytics, undefined, 'logged-out'),
    [analytics]
  );
}
