import { Link } from '@remix-run/react';
import { $path } from 'remix-routes';
import { useHydrated } from 'remix-utils/use-hydrated';

import { LunaParkLogo } from '../../src/components/icons/LogoIcon';
import { makeMarketLink } from '../../src/utils/makeLinks';
import { AnonNav } from './AnonNav';
import { ContentLoader } from './SSRCompatible/ContentLoader';

function SignInOrHomeLink(props?: { homePath?: string }) {
  const isHydrated = useHydrated();

  const hasToken = isHydrated ? !!localStorage.getItem('token') : false;

  return isHydrated ? (
    <Link
      to={hasToken ? props?.homePath ?? '/home' : '/login'}
      className='hover:text-red-006 transform-gpu transition-colors duration-300'
    >
      {hasToken ? 'Home' : 'Sign in'}
    </Link>
  ) : (
    <ContentLoader
      uniqueKey='anon-header-sign-in-loader'
      speed={2}
      width={50}
      height={20}
      viewBox='0 0 50 20'
      backgroundColor='#222'
      foregroundColor='#333'
    >
      <rect x='0' y='0' rx='3' ry='3' width='50' height='20' />
    </ContentLoader>
  );
}

export function AnonHeader() {
  return (
    <header
      className={`
        w-full flex-none h-14 bg-black border-b border-secondary 
        flex items-center justify-center text-white px-20`}
    >
      <div className='flex items-center justify-between w-full h-full'>
        <div className='h-full flex items-center gap-7.5 text-sms'>
          <a href={`${makeMarketLink('')}`}>
            <LunaParkLogo className='w-27 h-7.5 mr-4' />
          </a>

          <AnonNav />
        </div>

        <div className='flex items-center gap-5 text-sms pr-2'>
          <SignInOrHomeLink homePath={$path('/home')} />
        </div>
      </div>
    </header>
  );
}
