import { $path } from 'remix-routes';

import {
  type DtoPage,
  type DtoPageSection,
  type EnumsPageName,
} from '@lp-lib/api-service-client/public';

import { useAnonAppAnalytics } from '../../../src/analytics/app/anon';
import { useNavigateToSection } from '../DynamicPage/PageSectionContainer';
import { PageDisplayName } from '../DynamicPage/utils';
import {
  PublicLibraryNav,
  PublicLibraryNavSearchBar,
} from './PublicLibraryNav';

export function AnonPublicLibraryNav(props: {
  pageName: EnumsPageName;
  page: DtoPage;
  isSectionSelected?: (section: DtoPageSection) => boolean;
}) {
  const { pageName, page, isSectionSelected } = props;

  const navigateToSection = useNavigateToSection($path('/explore'));
  const analytics = useAnonAppAnalytics();

  return (
    <PublicLibraryNav
      page={page}
      onSectionClick={(section, index) => {
        navigateToSection(section);
        analytics.trackSectionNavClicked({
          pageName: PageDisplayName(pageName),
          sectionTitle: section.title,
          sectionId: section.id,
          index,
        });
      }}
      isSectionSelected={isSectionSelected}
      actions={
        <div className='flex-none flex items-center gap-3'>
          <PublicLibraryNavSearchBar
            targetPath={$path('/explore/search')}
            analytics={analytics}
          />
        </div>
      }
      className={' pl-12.5 pr-20'}
    />
  );
}
