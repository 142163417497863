import { Link } from '@remix-run/react';
import { useSessionStorage } from 'react-use';

import { GameCoverClip } from '../../../src/components/Game/GamePackCoverPres';
import { CloseIcon } from '../../../src/components/icons/CloseIcon';
import config from '../../../src/config';
import { AnonHeader } from '../AnonHeader';
import { PageLoadingBar } from '../PageLoadingBar';

function BookDemoBanner() {
  const [show, setShow] = useSessionStorage('show-book-demo-banner', true);

  if (!show) {
    return null;
  }
  return (
    <div
      className='relative w-full h-15 flex justify-center items-center gap-4.5'
      style={{
        background: 'linear-gradient(90deg, #614600 0%, #2D2000 100%)',
      }}
    >
      <p className='text-base font-bold text-tertiary'>
        Unlimited Access to 100s of Experiences for the price of a single event
      </p>
      <Link
        to={config.hubspot.bookDemoUrl}
        className={`w-50 h-10 bg-tertiary hover:bg-yellow-001 btn rounded flex items-center justify-center text-sms font-bold font-Montserrat text-black`}
        target='_blank'
      >
        Book a Demo
      </Link>

      <button
        type='button'
        onClick={() => setShow(false)}
        className='absolute right-22 text-black'
      >
        <CloseIcon className='w-3.5 h-3.5 fill-current' />
      </button>
    </div>
  );
}

export function AnonPublicLibraryLayout(props: {
  children: React.ReactNode;
  contentKey?: string;
  secondaryNav?: React.ReactNode;
}) {
  const { children, contentKey, secondaryNav } = props;

  return (
    <div className='w-full h-full fixed flex flex-col'>
      <PageLoadingBar color='#f11946' />

      <AnonHeader />

      {secondaryNav}

      <BookDemoBanner />

      <div
        // In client-side navigation, the scroll position is not reset.
        // This is a workaround to reset the scroll position when navigating to a new page.
        key={contentKey}
        className='relative w-full flex-1 overflow-auto bg-modal text-white scrollbar'
      >
        {children}
      </div>

      <GameCoverClip id='game-cover-clip' />
    </div>
  );
}
